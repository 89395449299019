import React, { FC, useContext, useEffect, useState } from 'react';
import { JournalError } from 'src/components/JournalError';
import { JournalLoading } from 'src/components/JournalLoading';
import { useGetWOWContent } from 'src/operations/queries/getWaysOfWorking';
import { GridWrapper, GridItem, Container, Button } from '@jsluna/react';
import { SideMenu } from './components/sideMenu';
import { scrollToElement } from 'src/utils/scrollTo';
import { wowView } from 'src/utils/getWowView';
import { useNavigate, useParams } from 'react-router-dom';
import { Navigation } from './components/navigation';
import { WOWContainer } from './waysOfWorking.styles';
import { ArrowTop } from '@jsluna/icons';
import { IconProps } from 'src/interfaces/icon.interface';
import { WaysOfWorkingContent } from './components/waysOfWorkingContent/waysOfWorkingContent';
import { BottomMenu } from './components/bottomMenu/bottomMenu';
import DOMPurify from 'dompurify';
import { routes } from 'src/constants/routes';
import { AuthContext, IAuthType } from 'src/providers/AuthProvider';

const ArrowTopIcon = ArrowTop as unknown as React.FC<IconProps>;

export const WaysOfWorkingPage: FC = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { authState, isGmUser } = authContext as {
    authState: IAuthType;
    isGmUser: (division: string[]) => boolean;
  };
  const { division, accountId } = authState;
  const { section, subSection } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showContent, setShowContent] = useState(false);
  const [sectionsTitles, setSectionsTitles] = useState<string[]>([]);
  const setSubSection = (index: number) =>
    setTimeout(() => {
      scrollToElement(`#sub-section-${index}`);
    }, 500);

  const setPathWithIndex = (section: any, sub_section?: any) => {
    const url = wowView.getUrl({
      section: section,
      ...(sub_section && { sub_section: sub_section }),
    });

    navigate(url);
  };

  const {
    data: { wow: waysOfWorking } = {},
    error: wowError,
    loading: wowLoading,
  } = useGetWOWContent();

  useEffect(() => {
    if (waysOfWorking?.sections) {
      const sections = waysOfWorking?.sections?.map(
        (section: any) => section.title,
      );
      setSectionsTitles(sections);
    }
  }, [waysOfWorking]);

  useEffect(() => {
    if (section) {
      const index = parseInt(section, 10) || 0;
      setCurrentIndex(index);
    }
    if (!section && window.innerWidth >= 800) {
      setCurrentIndex(1);
    }
    if (section && subSection) {
      const sub_index = parseInt(subSection, 10) || 1;
      setSubSection(sub_index);
    }
  }, [section, subSection]);

  useEffect(() => {
    setShowContent(currentIndex > 0);
  }, [currentIndex]);

  const revisionDate = (revisionDate: string) =>
    new Date(revisionDate).toLocaleString('en-GB', {
      month: 'long',
      year: 'numeric',
    });

  useEffect(() => {
    if (!division) return;
    if (isGmUser(division)) navigate(routes.root);
  }, [division]);

  return accountId && !isGmUser(division) ? (
    <WOWContainer>
      {wowLoading ? (
        <JournalLoading />
      ) : wowError ? (
        <JournalError />
      ) : (
        <div className=''>
          <GridWrapper equalHeight>
            <GridItem
              size={{ default: '1', md: '3/12' }}
              className='ln-u-soft-top-xl ln-u-bg-color-white ln-u-align-self-stretch ways-of-working__side-panel t-side-menu'
            >
              <SideMenu
                setSubSection={setSubSection}
                currentIndex={currentIndex}
                setCurrentIndex={setPathWithIndex}
                sections={waysOfWorking?.sections}
                sectionsTitles={sectionsTitles}
              />
            </GridItem>
            <GridItem
              size={{ default: '1', md: '9/12' }}
              className='border-left-grey-light ln-u-align-self-stretch ways-of-working__main-panel'
            >
              <GridWrapper
                horizontalAlign='center'
                className='wow-article ln-u-flush-sides'
              >
                <GridItem size='1' className='ln-u-bg-color-white wow-content'>
                  <Navigation
                    section={section}
                    showContent={showContent}
                    wrapperStyle='ln-u-hard-sides ln-u-soft-top-lg navigation__breadcrumbs'
                    activeIndex={1}
                  />
                  <Container className='ln-u-flush-sides t-header'>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(waysOfWorking?.header_title),
                      }}
                    />
                    <hr className='border-bottom-grey-light' />
                    <h1
                      className='ln-u-body-2 ln-u-flush-bottom ln-u-h4'
                      data-testid='revision-date'
                    >
                      {revisionDate(waysOfWorking.revision_date)}
                    </h1>
                  </Container>
                </GridItem>
                <GridItem
                  size='1'
                  className='ln-u-bg-color-white t-side-menu__mobile'
                >
                  {!showContent && (
                    <SideMenu
                      setSubSection={setSubSection}
                      currentIndex={currentIndex}
                      setCurrentIndex={setPathWithIndex}
                      sections={waysOfWorking?.sections}
                      sectionsTitles={sectionsTitles}
                    />
                  )}
                </GridItem>
                {showContent && (
                  <GridItem
                    size='1'
                    className='ln-u-bg-color-white wow-content'
                  >
                    <WaysOfWorkingContent
                      currentIndex={currentIndex}
                      sections={waysOfWorking.sections}
                    />

                    <Button
                      className={`back-to-top ln-c-button--filled scroll-back-to-top`}
                      onClick={() => scrollToElement('.t-header')}
                    >
                      <ArrowTopIcon aria-label='back to top' />
                    </Button>
                  </GridItem>
                )}
                {showContent && (
                  <GridItem
                    size='1'
                    className='ln-u-bg-color-grey-v-light ln-u-hard-sides ln-u-padding-top ln-u-padding-bottom*1/2'
                  >
                    <BottomMenu
                      showNumberInTitle={false}
                      setCurrentIndex={setPathWithIndex}
                      sectionsTitles={sectionsTitles}
                      currentIndex={currentIndex}
                      totalSections={waysOfWorking.sections?.length || 0}
                    />
                  </GridItem>
                )}
              </GridWrapper>
            </GridItem>
          </GridWrapper>
        </div>
      )}
    </WOWContainer>
  ) : (
    <>
      <JournalLoading />
    </>
  );
};
