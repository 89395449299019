import React, { createContext, useState } from 'react';
import { APP_ACCESS } from '../enums/permissions.enum';

export interface IAuthType {
  appAdmin: string[];
  appApprover: string[];
  accountTypeAdmin: string[];
  accountTypeApprover: string[];
  permissions: string[];
  sites: string[];
  isProspective: boolean;
  isSupplier: boolean;
  accountId?: string;
  division: string[];
}

const defaultAuthState: IAuthType = {
  accountTypeAdmin: [],
  accountTypeApprover: [],
  appAdmin: [],
  appApprover: [],
  permissions: [],
  sites: [],
  isProspective: false,
  isSupplier: false,
  division: [],
};

export const AuthContext = createContext({});

export const AuthProvider: React.FC<any> = ({ children }) => {
  const [authState, setAuthState] = useState<IAuthType>(defaultAuthState);

  const hasPermission = (permission: string): boolean =>
    !!permission && !!authState && authState?.permissions.includes(permission);
  const isGmUser = (division: string): boolean =>
    !!division && !!authState && authState?.division.includes('GM');
  const isAppAdminOf = (app: string): boolean => {
    return (
      (!!app && authState?.appAdmin.includes(app)) ||
      authState?.permissions.includes(APP_ACCESS.GLOBAL_APPLICATION_ADMIN)
    );
  };

  const isAppApproverOf = (app: string): boolean => {
    return (
      (!!app && authState?.appApprover.includes(app)) ||
      authState?.permissions.includes(APP_ACCESS.GLOBAL_APPLICATION_APPROVER)
    );
  };

  const isAccountTypeAdminOf = (accountType: string): boolean => {
    return (
      (!!accountType && authState?.accountTypeAdmin.includes(accountType)) ||
      authState?.permissions.includes(APP_ACCESS.GLOBAL_ACCOUNT_TYPE_ADMIN)
    );
  };

  const isAccountTypeApproverOf = (accountType: string): boolean => {
    return (
      (!!accountType && authState?.accountTypeApprover.includes(accountType)) ||
      authState?.permissions.includes(APP_ACCESS.GLOBAL_ACCOUNT_TYPE_APPROVER)
    );
  };

  const hasSite = (site: string): boolean =>
    !!site && !!authState && authState?.sites.includes(site);

  const isAnAppAdmin = (): boolean =>
    authState.appAdmin?.length > 0 ||
    authState?.permissions.includes(APP_ACCESS.GLOBAL_APPLICATION_ADMIN);
  const isAnAppApprover = (): boolean =>
    authState.appApprover?.length > 0 ||
    authState?.permissions.includes(APP_ACCESS.GLOBAL_APPLICATION_APPROVER);
  const isAnAccountTypeAdmin = (): boolean =>
    authState.accountTypeAdmin?.length > 0 ||
    authState?.permissions.includes(APP_ACCESS.GLOBAL_ACCOUNT_TYPE_ADMIN);
  const isAnAccountTypeApprover = (): boolean =>
    authState.accountTypeApprover?.length > 0 ||
    authState?.permissions.includes(APP_ACCESS.GLOBAL_ACCOUNT_TYPE_APPROVER);

  const hasClaim = (scope: string) => {
    const parts = scope.split(`:`);
    return (
      (authState as { [index: string]: any })[parts[0]] as string[]
    )?.includes(parts[1]);
  };

  const value = {
    authState,
    setAuthState,
    hasPermission,
    isAppAdminOf,
    isAppApproverOf,
    isAccountTypeAdminOf,
    isAccountTypeApproverOf,
    hasSite,
    isAnAppAdmin,
    isAnAppApprover,
    isAnAccountTypeAdmin,
    isAnAccountTypeApprover,
    hasClaim,
    isGmUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
