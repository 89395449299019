import React, { FC } from 'react';
import Container from 'src/components/Container/Container';
import { useAppSelector } from 'src/redux/hooks';
import { getProfileDetails } from 'src/redux/selectors';
import { PageHeader } from 'src/components/PageHeader';
import { capitalize } from 'src/utils/capitalize';
import { gql } from '@apollo/client';
import { SectionHeader } from 'src/shared/components/sectionHeader';
import { Card, GridWrapper, GridItem } from '@jsluna/react';
import { AppTile } from '@sainsburys-tech/supexp-app-tile';
import { useGmUser_MyApplicationsQuery } from 'src/operations/generated/graphql';
import { useNavigate } from 'react-router';
import { getGreeting } from 'src/utils/getGreeting';

gql`
  fragment GmUser_ApplicationInfo on ApplicationProjection {
    id
    acronym
    name
    shortDescription
    longDescription
    slug
    isLive
    proxyLocation
    isRoot
    type
  }
`;
gql`
  query GmUser_MyApplications {
    myApplications {
      ...GmUser_ApplicationInfo
    }
  }
`;

export const GmDashboard: FC = () => {
  const profile = useAppSelector(getProfileDetails);
  const navigate = useNavigate();

  const { data: { myApplications: applications } = {} } =
    useGmUser_MyApplicationsQuery();

  return (
    <>
      <PageHeader
        heading={`${getGreeting()} ${capitalize(
          profile?.preferredName || profile?.firstName || '',
        )},`}
        topHeading='Together With'
        subHeading='Welcome to Together With.'
      />

      <Container className='ln-u-hard-top'>
        {applications && applications?.length > 0 && (
          <>
            <SectionHeader heading='My apps' />
            <Card>
              <GridWrapper>
                {applications.map(application => (
                  <GridItem
                    size={{ md: '1/3', xs: '1/1' }}
                    className='app-grid-item'
                    key={application.id}
                  >
                    <AppTile
                      application={application}
                      navigateToDetailsPage={() => {
                        navigate(`/applications/${application.id}`);
                      }}
                    />
                  </GridItem>
                ))}
              </GridWrapper>
            </Card>
          </>
        )}
      </Container>
    </>
  );
};
