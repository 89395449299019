import React, { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Container from 'src/components/Container/Container';
import { PageHeader } from '../../components/PageHeader';
import { DetailsRow } from 'src/shared/components/detailsRow';
import { DetailsCard } from './viewSite.styles';
import { Section } from '../../components/Sections/section.styles';
import { AccountSelectorSection } from '../../components/Sections/AccountSelectorSection';
import { gql } from '@apollo/client';
import { Tick, Cancel, ErrorCircle } from '@jsluna/icons';
import { DomainSection } from '../../components/Sections/DomainSection';
import { viewOrganisationRoute } from 'src/constants/routes';
import { AuthContext } from 'src/providers/AuthProvider';
import {
  AccountSelectorSection_AccountsFragment,
  useAddApprovedDomainToSiteMutation,
  useAddRejectedDomainToSiteMutation,
  useRemoveApprovedDomainsFromSiteMutation,
  useRemoveRejectedDomainsFromSiteMutation,
  useSiteAddApprovedAccountsMutation,
  useSiteRemoveApprovedAccountMutation,
  useViewSiteQuery,
} from 'src/operations/generated/graphql';

const TickIcon = Tick as unknown as React.FC<{ className: string }>;
const CancelIcon = Cancel as unknown as React.FC<{ className: string }>;
const WarningIcon = ErrorCircle as unknown as React.FC<{ className: string }>;

gql`
  fragment ViewSite on SiteProjection {
    id
    name
    supplierNumber
    supplierCode
    activeStatus
    status
    approvedDomains
    rejectedDomains
    division
    parentGroup {
      value {
        id
        name
      }
    }
    accounts {
      value {
        ...AccountSelectorSection_accounts
      }
    }
  }
`;

gql`
  query ViewSite($id: String!) {
    site(id: $id) {
      ...ViewSite
    }
  }
`;

export const ViewSite: FC = () => {
  const { siteId } = useParams();
  const [accounts, setAccounts] = useState<
    AccountSelectorSection_AccountsFragment[]
  >([]);
  const [approvedDomains, setApprovedDomains] = useState<string[]>([]);
  const [rejectedDomains, setRejectedDomains] = useState<string[]>([]);
  // @ts-ignore
  const { isAnAccountTypeAdmin, isAnAccountTypeApprover } =
    useContext(AuthContext);

  const { data: { site = null } = {} } = useViewSiteQuery({
    variables: { id: siteId || '' },
  });

  useEffect(() => {
    if (!site) {
      setAccounts([]);
      setApprovedDomains([]);
      setRejectedDomains([]);
      return;
    }

    const { accounts, approvedDomains, rejectedDomains } = site;
    setAccounts(accounts.map(account => account.value));
    setApprovedDomains(approvedDomains);
    setRejectedDomains(rejectedDomains);
  }, [site]);

  const [
    addApprovedAccountMutation,
    { loading: addApprovedAccountLoading, error: addApprovedAccountError },
  ] = useSiteAddApprovedAccountsMutation();

  const handleAccountSelectionConfirmation = async (
    accountsSelected: AccountSelectorSection_AccountsFragment[],
  ) => {
    if (!site) return;
    const selectedIds = accountsSelected.map(account => account.id);
    try {
      await addApprovedAccountMutation({
        variables: { siteId: site.id, accountIds: selectedIds },
      });
      setAccounts([...accounts, ...accountsSelected]);
    } catch (e) {}
  };

  const [
    addApprovedDomainMutation,
    { error: addApprovedDomainError, loading: addApprovedDomainLoading },
  ] = useAddApprovedDomainToSiteMutation();
  const [
    addRejectedDomainMutation,
    { error: addRejectedDomainError, loading: addRejectedDomainLoading },
  ] = useAddRejectedDomainToSiteMutation();

  const [
    removeApprovedDomainsMutation,
    {
      error: removeApprovedDomainsError,
      loading: removeApprovedDomainsLoading,
    },
  ] = useRemoveApprovedDomainsFromSiteMutation();

  const [
    removeRejectedDomainsMutation,
    {
      error: removeRejectedDomainsError,
      loading: removeRejectedDomainsLoading,
    },
  ] = useRemoveRejectedDomainsFromSiteMutation();

  const [
    removeApprovedAccountMutation,
    {
      loading: removeApprovedAccountLoading,
      error: removeApprovedAccountError,
    },
  ] = useSiteRemoveApprovedAccountMutation();

  const handleAccountSelectionRemoval = async (accountId: string) => {
    if (!site) return;
    const filteredAccounts = accounts.filter(
      account => account.id !== accountId,
    );
    try {
      await removeApprovedAccountMutation({
        variables: { siteId: site.id, accountId },
      });
      setAccounts(filteredAccounts);
    } catch (e) {}
  };

  const handleApprovedDomainConfirmation = async (domain: string) => {
    if (!site) return;
    try {
      await addApprovedDomainMutation({
        variables: {
          id: site.id,
          domain,
        },
      });
      setApprovedDomains([...approvedDomains, domain]);
    } catch (e) {}
  };
  const handleRejectedDomainConfirmation = async (domain: string) => {
    if (!site) return;
    try {
      await addRejectedDomainMutation({
        variables: {
          id: site.id,
          domain,
        },
      });
      setRejectedDomains([...rejectedDomains, domain]);
    } catch (e) {}
  };

  const handleApprovedDomainsRemoval = async (selectedDomains: string[]) => {
    if (!site) return;
    try {
      await removeApprovedDomainsMutation({
        variables: {
          id: site.id,
          domains: selectedDomains,
        },
      });
      const filteredDomains = approvedDomains.filter(
        domain => !selectedDomains.includes(domain),
      );
      setApprovedDomains(filteredDomains);
    } catch (e) {}
  };

  const handleRejectedDomainsRemoval = async (selectedDomains: string[]) => {
    if (!site) return;
    try {
      await removeRejectedDomainsMutation({
        variables: {
          id: site.id,
          domains: selectedDomains,
        },
      });
      const filteredDomains = rejectedDomains.filter(
        domain => !selectedDomains.includes(domain),
      );
      setRejectedDomains(filteredDomains);
    } catch (e) {}
  };

  const verifiedStatusIcon = (status: string) => {
    switch (status) {
      case 'VERIFIED':
        return <TickIcon className={'icon tick'} />;
      case 'TEMP':
      case 'PERSPECTIVE':
        return <WarningIcon className={'icon warning'} />;
    }
  };

  return (
    <>
      <PageHeader
        heading={`${site?.name || 'Site name'}`}
        breadcrumbLinks={[
          { name: 'home', link: '/' },
          { name: 'Sites', link: '/sites' },
          { name: site?.name || 'Site name', link: '' },
        ]}
      />
      {site ? (
        <Container size='md'>
          <DetailsCard>
            <Section>
              <h4 className='display-1 ln-u-margin-bottom*3'>Details</h4>
              <DetailsRow name='Sid' value={site.id} />
              <DetailsRow name='Name' value={site.name || ''} />
              {site?.parentGroup?.value.name && (
                <DetailsRow
                  name='Parent group:'
                  value={site?.parentGroup?.value.name || 'N/A'}
                  link={
                    site?.parentGroup?.value.name &&
                    viewOrganisationRoute({
                      organisationId: site?.parentGroup.value.id,
                    })
                  }
                />
              )}
              <DetailsRow name='Number:' value={site.supplierNumber || 'N/A'} />
              <DetailsRow name='Code:' value={site.supplierCode || 'N/A'} />
              <DetailsRow name='Division:' value={site.division || 'N/A'} />
              <DetailsRow
                name='Active status:'
                value={site.activeStatus ? 'ACTIVE' : 'INACTIVE'}
                icon={
                  site.activeStatus ? (
                    <TickIcon className={'icon tick'} />
                  ) : (
                    <CancelIcon className={'icon cancel'} />
                  )
                }
              />

              <DetailsRow
                name='Verified status:'
                value={site.status || ''}
                icon={verifiedStatusIcon(site.status || '')}
              />
            </Section>
            {(isAnAccountTypeAdmin() || isAnAccountTypeApprover()) && (
              <>
                <DomainSection
                  disabled={!isAnAccountTypeAdmin()}
                  loading={
                    addApprovedDomainLoading ||
                    removeApprovedDomainsLoading ||
                    addRejectedDomainLoading ||
                    removeRejectedDomainsLoading
                  }
                  error={
                    addApprovedDomainError ||
                    removeApprovedDomainsError ||
                    addRejectedDomainError ||
                    removeRejectedDomainsError
                  }
                  target={{ id: 'N/A', displayName: site.id }}
                  existingSelectedApprovedDomains={approvedDomains}
                  existingSelectedRejectedDomains={rejectedDomains}
                  handleApprovedConfirmation={handleApprovedDomainConfirmation}
                  handleRejectedConfirmation={handleRejectedDomainConfirmation}
                  handleApprovedRemoval={handleApprovedDomainsRemoval}
                  handleRejectedRemoval={handleRejectedDomainsRemoval}
                />

                <AccountSelectorSection
                  disabled={!isAnAccountTypeAdmin()}
                  handleRemoval={handleAccountSelectionRemoval}
                  existingAccounts={accounts}
                  loading={
                    addApprovedAccountLoading || removeApprovedAccountLoading
                  }
                  error={addApprovedAccountError || removeApprovedAccountError}
                  handleConfirmation={handleAccountSelectionConfirmation}
                  target={{
                    id: site.id,
                    displayName: site.name || '',
                    type: 'User account',
                    origin: 'site',
                  }}
                />
              </>
            )}
          </DetailsCard>
        </Container>
      ) : (
        <p>No Data</p>
      )}
    </>
  );
};
