import { Outlet, useNavigate, Navigate } from 'react-router-dom';
import { LoginPage } from 'src/pages/login';
import { ProtectedLayoutContainer } from './PPS.styles';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { getIsLoggedIn, getProfileDetails } from 'src/redux/selectors';

import { setInitialState } from 'src/redux/reducer';

//@ts-ignore
import { EventSourcePolyfill } from 'event-source-polyfill';
import {
  SelectedAccountFragment,
  useProtectedLayout_MyProfileLazyQuery,
} from '../../operations/generated/graphql';
import { GlobalHeader } from '@sainsburys-tech/supplier-experience-global-header';
import { AuthContext, IAuthType } from '../../providers/AuthProvider';
import { ClaimsEnum, INav, navList } from '../siteNavigation';
import { APP_ACCESS } from 'src/enums/permissions.enum';
import { gql } from '@apollo/client';

import { Modal } from '@jsluna/react';
import { AddTagsComponent } from 'src/shared/components/addTagsComponent';
import { checkIfURL } from 'src/utils/checkURL';
import { GlobalLoader } from './components/globalLoader';
import { routes } from 'src/constants/routes';

//!! necessary for the eventsource
const EventSource = EventSourcePolyfill;
global.EventSource = EventSource;

const SSE_ENDPOINT = process.env.REACT_APP_SSE_ENDPOINT ?? ``;

gql`
  fragment SelectedAccount on AccountProjection {
    id
    name
    accountTypeId
    accountSetup
    tags {
      value {
        id
        name
      }
    }
  }
`;

gql`
  query ProtectedLayout_MyProfile {
    myProfile {
      id
      firstName
      lastName
      preferredName
      emailAddress
      title
      contactNumber
      countryCode
      selectedAccountId
      status
      accounts {
        value {
          ...SelectedAccount
        }
      }
    }
  }
`;

interface IPPSLayout {
  isPublic?: boolean;
}

const tempDomain = 'togetherwith.core.commercial.sainsburys.co.uk';

export const PPSLayout: FC<IPPSLayout> = ({ isPublic }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const myProfile = useAppSelector(getProfileDetails);
  const authContext = useContext(AuthContext);
  const [siteNavigation, setSiteNavigation] = useState<INav[]>([]);
  const [loading, setLoading] = useState(false);
  const [activeAccount, setActiveAccount] =
    useState<SelectedAccountFragment | null>();
  const [showTagsModal, setShowTagsModal] = useState(false);

  const {
    hasPermission,
    authState,
    hasClaim,
    setAuthState,
    isAnAppAdmin,
    isAnAppApprover,
    isAnAccountTypeAdmin,
    isAnAccountTypeApprover,
  } = authContext as {
    hasPermission: (permission: string) => boolean;
    authState: IAuthType;
    hasClaim: (permission: string) => boolean;
    setAuthState: (state: IAuthType) => void;
    isAnAppAdmin: () => boolean;
    isAnAppApprover: () => boolean;
    isAnAccountTypeAdmin: () => boolean;
    isAnAccountTypeApprover: () => boolean;
  };

  const { division } = authState;

  useEffect(() => {
    // if (!authState?.accountId) return;
    const checkClaims = (item: INav) => {
      if (!item.scope) {
        return true;
      } else {
        switch (item.scope) {
          case ClaimsEnum.ACCOUNT_TYPE:
            return (
              isAnAccountTypeAdmin() ||
              isAnAccountTypeApprover() ||
              hasPermission(APP_ACCESS.CREATE_ACCOUNT_TYPE)
            );

          case ClaimsEnum.APP_ADMIN:
            return isAnAppAdmin() || isAnAppApprover();

          default:
            return hasClaim(item.scope);
        }
      }
    };

    const canSeeProtectedMenu = (isPublic?: boolean) => {
      return isPublic ? true : isLoggedIn && !isPublic;
    };

    const filterMenuItems = (
      menuItems: INav[],
      conditionCheck: (item: INav) => boolean,
    ) => {
      return menuItems.reduce((filteredItems: INav[], item) => {
        if (conditionCheck(item)) {
          if (item.subNav && item.subNav.length > 0) {
            const filteredSubNav = filterMenuItems(item.subNav, conditionCheck);
            if (filteredSubNav.length > 0) {
              item.subNav = filteredSubNav;
              filteredItems.push(item);
            }
          } else if (!item.subNav && canSeeProtectedMenu(item.isPublic)) {
            if (
              (division.includes('GM') && item.gmView) ||
              !division.includes('GM')
            ) {
              filteredItems.push({
                ...item,
                navigateFunction: checkIfURL(item.path)
                  ? undefined
                  : () => {
                      navigate(item.path);
                    },
              });
            }
          }
        }
        return filteredItems;
      }, []);
    };

    const filteredMenu = filterMenuItems(navList, checkClaims);
    setSiteNavigation(filteredMenu);
  }, [authState]);

  useEffect(() => {
    const host = window.location.host;
    if (host === tempDomain) {
      navigate(routes.pageMoved);
    }
  }, []);

  const [
    getLoggedInProfile,
    { data: { myProfile: loggedInProfile = null } = {} },
  ] = useProtectedLayout_MyProfileLazyQuery();

  useEffect(() => {
    if (!myProfile) {
      getLoggedInProfile();
    }
  }, []);

  useEffect(() => {
    if (!loggedInProfile) return;

    setLoading(true);

    const accounts =
      loggedInProfile?.accounts.map(account => account.value) || [];

    fetch('/claims')
      .then(response => response.json())
      .then(data => {
        setAuthState(data);
      })
      .catch(error => console.log(`[Claims] Error`, { error }))
      .finally(() => setLoading(false));

    const currentAccount =
      accounts.find(
        account => account.id === loggedInProfile?.selectedAccountId,
      ) || null;
    const data = {
      profileDetails: {
        id: loggedInProfile.id,
        title: loggedInProfile.title,
        firstName: loggedInProfile.firstName,
        lastName: loggedInProfile.lastName,
        preferredName: loggedInProfile.preferredName,
        emailAddress: loggedInProfile.emailAddress,
        contactNumber: loggedInProfile.contactNumber,
        countryCode: loggedInProfile.countryCode,
        selectedAccountId: loggedInProfile.selectedAccountId,
        status: loggedInProfile.status,
      },
      accounts,
      selectedAccount: currentAccount,
      isLoggedIn: true,
    };

    setActiveAccount(currentAccount);
    if (currentAccount && !currentAccount.accountSetup) {
      setShowTagsModal(true);
    }

    dispatch(setInitialState(data));
  }, [loggedInProfile]);

  const memoizedMenu = useMemo(() => {
    return <GlobalHeader siteNavigation={siteNavigation} />;
  }, [siteNavigation]);

  return (
    <>
      <ProtectedLayoutContainer>
        <div className='route-container'>
          <div className='header-container'>
            {memoizedMenu}
            {loading && <GlobalLoader />}
          </div>

          {isPublic ? (
            <>
              <Outlet />
            </>
          ) : (
            <>
              {!loading && (
                <>
                  {isLoggedIn && (
                    <div>
                      <div className='route-container'>
                        <Outlet />
                      </div>

                      {activeAccount && showTagsModal && (
                        <Modal
                          restrictClose
                          open={showTagsModal}
                          headingId='select-tag-confirmation'
                          className='add-tags-modal'
                        >
                          <div>
                            <AddTagsComponent
                              account={activeAccount}
                              handleClose={() => {
                                setShowTagsModal(false);
                              }}
                            />
                          </div>
                        </Modal>
                      )}
                    </div>
                    // ) : (
                    //   <Navigate to={routes.login} />
                    //   <h1>Hello</h1>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </ProtectedLayoutContainer>
    </>
  );
};
