import React, { useContext } from 'react';
import { AuthContext, IAuthType } from 'src/providers/AuthProvider';
import { Dashboard } from '../dashboard';
import { Prospective } from '../prospective';
import { TWLandingPage } from '../twLandingPage';
import { useAppSelector } from 'src/redux/hooks';
import { getIsLoggedIn } from 'src/redux/selectors';
import { GmDashboard } from '../GmDashboard';

export const RootPage = () => {
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const { authState } = useContext(AuthContext) as {
    authState: IAuthType;
  };

  const { isProspective, division } = authState;

  return (
    <>
      {isLoggedIn ? (
        <>
          {isProspective ? (
            <Prospective />
          ) : division.includes('GM') ? (
            <GmDashboard />
          ) : (
            <Dashboard />
          )}
        </>
      ) : (
        <TWLandingPage />
      )}
    </>
  );
};
