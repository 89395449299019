import React, { FC, useContext, useEffect, useState } from 'react';
import { useGetUserTypes } from 'src/operations/queries/getUserType';
import { TopNavigation } from './components/topNavigation';
import { GridWrapper, GridItem, Container } from '@jsluna/react';
import { JournalError } from 'src/components/JournalError';
import { JournalLoading } from 'src/components/JournalLoading';
import { TermsPage } from './components/termsPage';
import { SUPPLIER, AGENT } from 'src/constants/roleTypes';
import { useGetTradingTerms } from 'src/operations/queries/getTradingTerms';
import { gql } from '@apollo/client';
import { useAppSelector } from 'src/redux/hooks';
import { getProfileDetails } from 'src/redux/selectors';
import {
  useAcceptTAndCMutation,
  useTermsAndConditions_AccountDetailsLazyQuery,
  useTermsAndConditions_GroupSitesLazyQuery,
} from 'src/operations/generated/graphql';
import { AuthContext, IAuthType } from 'src/providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/constants/routes';

gql`
  query TermsAndConditions_AccountDetails($id: String!) {
    account(id: $id) {
      id
      sites {
        value {
          name
          supplierCode
          supplierNumber
          approvedDomains
          rejectedDomains
          status
          activeStatus
          groupId
          parentGroup {
            value {
              id
            }
          }
        }
      }
    }
  }
`;

gql`
  fragment termsAndConditions_GroupSites on SiteProjection {
    id
    name
    supplierCode
    supplierNumber
    approvedDomains
    rejectedDomains
    status
    tncStatus
  }
`;

gql`
  query TermsAndConditions_GroupSites($groupId: String!) {
    groupSites(groupId: $groupId) {
      ...termsAndConditions_GroupSites
    }
  }
`;

export type PostTermsPayload = {
  body: {
    suppliers: string;
  };
  isAgent: boolean;
};

export const TermsAndConditions: FC = () => {
  const currentProfile = useAppSelector(getProfileDetails);
  const navigate = useNavigate();
  const { authState, isGmUser } = useContext(AuthContext) as {
    authState: IAuthType;
    isGmUser: (division: string[]) => boolean;
  };
  const { isSupplier, isProspective, division, accountId } = authState;
  const [loading, setLoading] = useState(false);
  const [acceptTermsStatus, setAcceptTermsStatus] = useState<
    'SUCCESS' | 'ERROR' | null
  >(null);

  const [getAccountDetails, { data: { account = null } = {} }] =
    useTermsAndConditions_AccountDetailsLazyQuery();

  const [acceptTAndC, { loading: acceptTAndCLoading }] =
    useAcceptTAndCMutation();

  const [getGroupSites, { data: groupSites }] =
    useTermsAndConditions_GroupSitesLazyQuery();

  const {
    data: { tradingTerms } = {},
    error: tradingTermsError,
    loading: tradingTermsLoading,
  } = useGetTradingTerms();

  const [supplierCodesAuthority, setSupplierCodesAuthority] = useState(false);

  const showSupplierCodes = isSupplier && !isProspective;

  const postAcceptedTerms = (payload: PostTermsPayload) => {
    setLoading(true);

    try {
      acceptTAndC({
        variables: {
          supplierCode: payload.body.suppliers,
        },
      }).then((data: any) => {
        setAcceptTermsStatus('SUCCESS');
        setLoading(false);
      });
    } catch (error) {
      setAcceptTermsStatus('ERROR');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentProfile?.selectedAccountId) {
      getAccountDetails({
        variables: {
          id: currentProfile.selectedAccountId,
        },
      });
    }
  }, [currentProfile]);

  useEffect(() => {
    if (account?.sites?.[0]?.value.parentGroup?.value.id) {
      getGroupSites({
        variables: {
          groupId: account?.sites?.[0]?.value.parentGroup?.value.id,
        },
      });
    }
  }, [account?.sites]);

  useEffect(() => {
    if (!division) return;
    if (isGmUser(division)) navigate(routes.root);
  }, [division]);

  return accountId && !isGmUser(division) ? (
    <div
      className='trading-terms-and-conditions-page ln-u-bg-color-grey-v-light'
      data-cy='trading-terms-and-conditions-page'
    >
      <TopNavigation />
      {tradingTermsLoading || tradingTermsError ? (
        <Container className='ln-u-soft-top-lg ln-u-soft-bottom'>
          <GridWrapper matrix horizontalAlign='center'>
            <GridItem size={{ default: '5/6', lg: '3/4' }}>
              {tradingTermsError && <JournalError />}
              {tradingTermsLoading && <JournalLoading />}
            </GridItem>
          </GridWrapper>
        </Container>
      ) : (
        <>
          <TermsPage
            showSupplierCodes={showSupplierCodes}
            supplierCodesAuthority={supplierCodesAuthority}
            setSupplierCodesAuthority={(isChecked: boolean) => {
              setSupplierCodesAuthority(isChecked);
            }}
            supplierCodes={groupSites?.groupSites || []}
            tradingTerms={tradingTerms}
            postAcceptedTerms={postAcceptedTerms}
            acceptedStatus={acceptTermsStatus}
          />
        </>
      )}
    </div>
  ) : (
    <>
      <JournalLoading />
    </>
  );
};
